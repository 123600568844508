import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { AboutContactContainer, BannerContainer } from '../styles'
import Img from 'gatsby-image'

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  backgroundImage
}) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <BannerContainer>
        <div>
          <h1>{title}</h1>
        </div>
        {backgroundImage &&
          <Img
            fluid={backgroundImage.childImageSharp.fluid}
            fadeIn={false}
            loading='eager'
            objectFit='cover'
            objectPosition='50% 50%'
          />}
      </BannerContainer>
      <AboutContactContainer>
        <PageContent content={content} />
      </AboutContactContainer>
    </>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  backgroundImage: PropTypes.object
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        backgroundImage={data.background}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    background: file(absolutePath: {regex: "/about-bg/"}) {
      ...InfoPageBanner
    }
  }
`
